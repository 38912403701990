import React from 'react'

import HomePageTemplate from '../templates/home-page'

import Seo from '../components/seo'

class SiteIndex extends React.Component {
  render() {
    return (
      <HomePageTemplate>
        <Seo
          title="Solidus | Development"
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
      </HomePageTemplate>
    )
  }
}

export default SiteIndex
